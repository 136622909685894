import * as React from "react";
import "./BottomSteps.sass";

export default (): JSX.Element => <div className="section BottomSteps has-text-light">
	<div className="container has-text-centered is-size-2">
		<ul>
			<li>1. Do 5 pushups with an object on your back</li>
			<li>2. Tag &amp; challenge 3 people you're grateful for</li>
			<li>3. Tag @whiskeybravo and #gotyourback</li>
		</ul>
	</div>
</div>;